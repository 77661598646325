@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-style:italic;src:url(0.woff2) format('woff2'),url(0.woff) format('woff');unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-style:italic;src:url(1.woff2) format('woff2'),url(1.woff) format('woff');unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-style:italic;src:url(2.woff2) format('woff2'),url(2.woff) format('woff');unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-style:italic;src:url(3.woff2) format('woff2'),url(3.woff) format('woff');unicode-range: U+1F00-1FFF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-style:italic;src:url(4.woff2) format('woff2'),url(4.woff) format('woff');unicode-range: U+0370-03FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-style:italic;src:url(5.woff2) format('woff2'),url(5.woff) format('woff');unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-style:italic;src:url(6.woff2) format('woff2'),url(6.woff) format('woff');unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-style:italic;src:url(7.woff2) format('woff2'),url(7.woff) format('woff');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;font-style:italic;src:url(8.woff2) format('woff2'),url(8.woff) format('woff');unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;font-style:italic;src:url(9.woff2) format('woff2'),url(9.woff) format('woff');unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;font-style:italic;src:url(a.woff2) format('woff2'),url(a.woff) format('woff');unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;font-style:italic;src:url(b.woff2) format('woff2'),url(b.woff) format('woff');unicode-range: U+1F00-1FFF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;font-style:italic;src:url(c.woff2) format('woff2'),url(c.woff) format('woff');unicode-range: U+0370-03FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;font-style:italic;src:url(d.woff2) format('woff2'),url(d.woff) format('woff');unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;font-style:italic;src:url(e.woff2) format('woff2'),url(e.woff) format('woff');unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;font-style:italic;src:url(f.woff2) format('woff2'),url(f.woff) format('woff');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;src:url(g.woff2) format('woff2'),url(g.woff) format('woff');unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;src:url(h.woff2) format('woff2'),url(h.woff) format('woff');unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;src:url(i.woff2) format('woff2'),url(i.woff) format('woff');unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;src:url(j.woff2) format('woff2'),url(j.woff) format('woff');unicode-range: U+1F00-1FFF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;src:url(k.woff2) format('woff2'),url(k.woff) format('woff');unicode-range: U+0370-03FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;src:url(l.woff2) format('woff2'),url(l.woff) format('woff');unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;src:url(m.woff2) format('woff2'),url(m.woff) format('woff');unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;src:url(n.woff2) format('woff2'),url(n.woff) format('woff');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;src:url(o.woff2) format('woff2'),url(o.woff) format('woff');unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;src:url(p.woff2) format('woff2'),url(p.woff) format('woff');unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;src:url(q.woff2) format('woff2'),url(q.woff) format('woff');unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;src:url(r.woff2) format('woff2'),url(r.woff) format('woff');unicode-range: U+1F00-1FFF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;src:url(s.woff2) format('woff2'),url(s.woff) format('woff');unicode-range: U+0370-03FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;src:url(t.woff2) format('woff2'),url(t.woff) format('woff');unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;src:url(u.woff2) format('woff2'),url(u.woff) format('woff');unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face {font-family:'Noto Sans Semi Condensed';font-display:swap;font-weight:700;src:url(v.woff2) format('woff2'),url(v.woff) format('woff');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
